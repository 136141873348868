import React from 'react';
import * as R from 'ramda';
import { getWindow } from '../../common/util.js';
import { Icon } from '../Icon.js';
import { objectToUrlParams } from '../util/url.js';
import { VisuallyHidden } from '@kesko/kespro-common-components';
import styles from './ShareButton.scss';
export const ShareButton = ({ network, pageUrl, text }) => (React.createElement("button", { className: styles.shareButton, onClick: shareHandler(network, pageUrl, text) },
    React.createElement(VisuallyHidden, null, network),
    React.createElement(Icon, { key: network, type: network })));
const networks = {
    facebook: {
        url(pageUrl, _text) {
            const params = objectToUrlParams({
                u: pageUrl,
            });
            return `https://www.facebook.com/sharer/sharer.php?${params}`;
        },
    },
    x: {
        url(pageUrl, text) {
            const params = objectToUrlParams({
                text: text ? `${text} ${pageUrl}` : pageUrl,
            });
            return `https://x.com/intent/post?${params}`;
        },
    },
    linkedin: {
        url(pageUrl) {
            const params = objectToUrlParams({
                url: pageUrl,
            });
            return `https://www.linkedin.com/sharing/share-offsite/?${params}`;
        },
    },
    whatsapp: {
        url(pageUrl, text) {
            const params = objectToUrlParams({
                text: text ? `${text} | ${pageUrl}` : text,
            });
            return `https://api.whatsapp.com/send?${params}`;
        },
    },
};
const windowConfig = R.pipe(R.toPairs, R.map(([key, value]) => `${key}=${value}`), (pairs) => pairs.join(', '));
function shareHandler(network, pageUrl, text) {
    const win = getWindow();
    const util = networks[network];
    const height = 400;
    const width = 550;
    if (!win) {
        return () => undefined;
    }
    const left = (win.outerWidth / 2) + (win.screenX || win.screenLeft || 0) - (width / 2);
    const top = (win.outerHeight / 2) + (win.screenY || win.screenTop || 0) - (height / 2);
    const config = {
        height,
        width,
        left,
        top,
        location: 'no',
        toolbar: 'no',
        status: 'no',
        directories: 'no',
        menubar: 'no',
        scrollbars: 'yes',
        resizable: 'no',
        centerscreen: 'yes',
        chrome: 'yes',
    };
    return () => {
        win.open(util.url(pageUrl, text), '', windowConfig(config));
    };
}
