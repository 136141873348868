import React from 'react';
import { useLocation } from 'react-router-dom';
import { NotFoundPage } from '../NotFoundPage/index.js';
import { defaultDescriptions, productMetaKds, getProductNameKds } from '../../meta.js';
import { useGetCategoryHierarchyQuery, useGetProductV2Query } from '../../services/api.js';
import { useAppSelector } from '../../hooks.js';
import { isAvailableProductPageLanguage, } from '../../../common/apiTypes.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { LoadingIndicator } from '../../components/LoadingIndicator.js';
import { RedirectWithStatus } from '../../components/RedirectWithStatus.js';
import qs from 'qs';
import { ProductCardComponent } from './ProductCardComponent/index.js';
import { KCCLocaleProvider } from '@kesko/kespro-common-components';
import styles from './style.scss';
import { PreloadAsset } from './ProductCardComponent/components/PreloadAsset/PreloadAsset.js';
const ProductPageBody = ({ product, productKds, categoryHierarchy, substitutes, language }) => {
    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
    return (React.createElement(React.Fragment, null,
        React.createElement(KCCLocaleProvider, { locale: language },
            React.createElement("div", { className: styles.productCardWrapper },
                React.createElement(ProductCardComponent, { productData: productKds, gtin: productKds.gtin, isLoading: false, categories: product.categories, hierarchy: categoryHierarchy, substitutes: substitutes, productName: product.name, isLoggedIn: isLoggedIn, language: language })))));
};
const ProductPageHelmet = ({ product, productKds, slugFromUrl, language }) => {
    const newPageMeta = productKds ? productMetaKds(product, productKds, slugFromUrl, language) : undefined;
    const images = productKds && productKds.assets;
    return (React.createElement(React.Fragment, null,
        images && productKds.gtin && React.createElement(PreloadAsset, { images: images, gtin: productKds.gtin }),
        React.createElement(KesproComHelmet, { title: getProductNameKds(productKds, language), canonicalPath: `/tuotteet/${slugFromUrl}`, description: newPageMeta ? newPageMeta.description : defaultDescriptions.products, pageMeta: newPageMeta })));
};
const ProductPage = ({ product, productKds, categoryHierarchy, substitutes, relatedProducts, slugFromUrl, language, }) => (React.createElement("div", null,
    React.createElement(ProductPageHelmet, { product: product, productKds: productKds, slugFromUrl: slugFromUrl, language: language }),
    React.createElement(ProductPageBody, { product: product, productKds: productKds, categoryHierarchy: categoryHierarchy, substitutes: substitutes, relatedProducts: relatedProducts, slugFromUrl: slugFromUrl, language: language })));
const ProductPageWrapper = (props) => {
    const { productSlugPrefix, ean } = props.match.params;
    const productSlug = `${productSlugPrefix}${ean}`;
    const location = useLocation();
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const lang = queryParams.lang;
    const validatedLang = isAvailableProductPageLanguage(lang) ? lang : 'fi';
    const { data: productData, error: productDataError, isLoading: isProductLoading, } = useGetProductV2Query({
        ean,
        lang: validatedLang,
    });
    const { error: categoryError, isLoading: isCategoryLoading } = useGetCategoryHierarchyQuery();
    const hierarchy = useAppSelector((state) => state.category.hierarchy);
    if (isProductLoading || isCategoryLoading) {
        return React.createElement(LoadingIndicator, { className: styles.loading });
    }
    if (productData && productData.oldProduct.product.slug !== productSlug) {
        return (React.createElement(RedirectWithStatus, { status: 301, to: `/tuotteet/${productData.oldProduct.product.slug}`, staticContext: props.staticContext }));
    }
    if (productData && productData.newProduct && hierarchy) {
        return (React.createElement(ProductPage, { categoryHierarchy: hierarchy, product: productData.oldProduct.product, productKds: productData.newProduct, substitutes: productData.oldProduct.substitutes, relatedProducts: productData.oldProduct.relatedProducts, slugFromUrl: productSlug, language: validatedLang }));
    }
    if (productDataError || categoryError) {
        return React.createElement(NotFoundPage, { language: "fi" });
    }
    return React.createElement(LoadingIndicator, { className: styles.loading });
};
export { ProductPageWrapper as NewProductPage };
